@font-face {
  font-family: "Noto Sans KR";
  font-display: fallback;
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400");
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}
