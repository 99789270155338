body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Noto Sans KR";
  font-display: fallback;
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400");
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  background-color: #f5f5f5 !important;
}
@media screen and (min-width: 768px) {
  .container {
    width: 750px;
  }
  .container.form {
    width: 550px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .container.form {
    width: 550px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  .container.form {
    width: 550px;
  }
}
@media screen and (max-width: 575px) {
  .container {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.title {
  color: #2947d2;
  margin-bottom: 24px;
}
.ant-form-item-label {
  text-align: left!important;
}

.bottom-divider {
  margin-bottom: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
.audience-image {
  background: url(/static/media/img_microphone.c27f2ae0.png) no-repeat;
  height: 503px;
  width: 100%;
  background-size: cover;
}
p {
  margin-bottom: 0;
}
.item-icon {
  color: #2947d2;
  font-size: 8px;
  margin-bottom: 2px;
}
.c-primary {
  color: #2947d2;
}
.apply-btn {
  margin-right: 20px;
}
.time-table .col {
  padding: 8px;
}
.time-table .table-header {
  text-align: center;
}
.time-table .table-row {
  font-size: 13px;
  border-top: 1px solid #e8e8e8;
}
.time-table .time-col {
  text-align: center;
  border-right: 1px solid #e8e8e8;
}
.time-table .content-col {
  border-right: 1px solid #e8e8e8;
}
.footer {
  padding: 48px 0;
  color: #999999;
  text-align: center;
}
.news-card {
  border: 1px solid #dbdbdb;
  padding: 24px;
  margin-bottom: 24px;
}
.news-card h1 {
  font-size: 24px !important;
  margin-bottom: 12px !important;
}
.hahm-map {
  width: 100%;
  height: 400px;
  margin-top: 12px;
}
@media screen and (max-width: 576px) {
  .apply-btn {
    margin-left: auto;
    margin-right: auto;
  }
  .audience-image {
    display: none;
  }
  .time-table .table-row {
    font-size: 11px;
  }
  .news-card {
    border: 1px solid #dbdbdb;
    padding: 24px;
    margin-bottom: 24px;
  }
  .news-card h1 {
    font-size: 18px !important;
    margin-bottom: 12px !important;
  }
  .hahm-map {
    width: 100%;
    height: 200px;
    margin-top: 12px;
  }
  .footer-logo {
    width: 300px;
  }
}

.gray-box {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  padding: 12px;
}

